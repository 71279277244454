/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  textContent: 'textContent',
  infoLayerContent: 'infoLayerContent',
  validationText: 'validationText',
  'Truck Camper': 'Truck Camper',
  '4WD-Camper': '4WD-Camper',
  'Campervan / Kastenwagen': 'Campervan / Kastenwagen',
  'Campingbus / Van': 'Campingbus / Van',
  'Wohnmobil Teilintegriert': 'Wohnmobil Teilintegriert',
  'Wohnmobil teilintegriert': 'Wohnmobil teilintegriert',
  'Wohnmobil Vollintegriert': 'Wohnmobil Vollintegriert',
  'Wohnmobil vollintegriert': 'Wohnmobil vollintegriert',
  'Wohnmobil Alkoven': 'Wohnmobil Alkoven',
  'Run of Fleet': 'Run of Fleet',
  Minicamper: 'Minicamper'
}; export default Keys;