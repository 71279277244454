import React, { useEffect, useRef } from 'react';
import { ApiBooking } from '@ibe/api';

declare const window: {
  trustbadge: { reInitialize: () => void };
};

const TrustedBadge = (props: { booking: ApiBooking }): JSX.Element => {
  const { booking } = props;
  const elementRef = useRef<HTMLDivElement>(null);
  const elementRefItem = elementRef.current;

  useEffect(() => {
    if (
      !!elementRefItem &&
      !!booking &&
      window &&
      window.trustbadge &&
      window.trustbadge.reInitialize
    ) {
      setTimeout(() => {
        window.trustbadge.reInitialize();
      }, 300);
    }
  }, [elementRefItem, booking]);

  return (
    <div>
      <div id="trustedShopsCheckout" hidden>
        <span id="tsCheckoutOrderNr">{booking?.bookingNumber}</span>
        <span id="tsCheckoutBuyerEmail">{booking?.client?.communicationDetails?.email}</span>
        <span id="tsCheckoutOrderAmount">{booking?.price?.finalPrice}</span>
        <span id="tsCheckoutOrderCurrency">{booking?.price?.currencyCode}</span>
        <span id="tsCheckoutOrderPaymentType">{booking?.payments[0]?.paymentData?.option}</span>
        <span id="tsCheckoutOrderEstDeliveryDate">{booking?.bookingDate}</span>
      </div>
      <div ref={elementRef} />
    </div>
  );
};

export default TrustedBadge;
