/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  addTraveller: 'addTraveller',
  next: 'next',
  participant: 'participant',
  traveller: 'traveller',
  inputRequired: 'inputRequired',
  minDriverAge: 'minDriverAge',
  mustBeOlderThan: 'mustBeOlderThan',
  salutation: 'salutation',
  male: 'male',
  female: 'female',
  firstname: 'firstname',
  lastname: 'lastname',
  dateOfBirth: 'dateOfBirth',
  day: 'day',
  month: 'month',
  year: 'year',
  maxNumber: 'maxNumber',
  removeParticipant: 'removeParticipant',
  cancel: 'cancel',
  continue: 'continue',
  salutationInfoText: 'salutationInfoText',
  nameInfoText: 'nameInfoText',
  dateOfBirthInfoText: 'dateOfBirthInfoText',
  wrongYear: 'wrongYear'
}; export default Keys;