import { observer, useLocalStore } from 'mobx-react';
import React, { useContext } from 'react';
import {
  CamperBreadcrumb,
  CamperSearchParams,
  useAppService,
  useBookingService,
  useConfig
} from '@ibe/components';
import { Route, Switch } from 'react-router-dom';
import { getPageByUrl, getPages, WidgetType } from './pages';
import LeaveModal from './Components/LeaveModal';
import CheckoutStore from './Pages/Checkout/CheckoutStore';
import { extendedApiContext, paymentContext } from './Config/config';
import useCmbConfig from './Hooks/useCmbConfig';

const App = observer(function App({
  widgetType,
  rerouteUrl,
  startPageUrl,
  breadcrumb,
  searchParams,
  affiliateNumber,
  useCustomCMP
}: {
  widgetType?: WidgetType;
  rerouteUrl?: string;
  startPageUrl?: string;
  breadcrumb?: CamperBreadcrumb;
  searchParams?: Partial<CamperSearchParams>;
  affiliateNumber?: string;
  apiKey?: string;
  useCustomCMP?: boolean;
}) {
  const config = useConfig();
  const cmbConfig = useCmbConfig();
  const { api } = useContext(extendedApiContext);
  const bookingService = useBookingService();
  const appService = useAppService();
  const paymentService = useContext(paymentContext);

  const store = useLocalStore(
    () =>
      new CheckoutStore(
        config,
        cmbConfig,
        api,
        bookingService,
        appService,
        paymentService,
        affiliateNumber
      )
  );

  return (
    <>
      <Switch>
        {Object.keys(getPages(widgetType)).map(url => {
          const page = getPageByUrl(url, widgetType);
          const routeProps = {
            exact: url === '/',
            path: url,
            key: url,
            children: page.component
          };
          return (
            <Route exact={routeProps.exact} path={routeProps.path} key={routeProps.key}>
              <routeProps.children
                store={store}
                widgetType={widgetType}
                rerouteUrl={rerouteUrl}
                startPageUrl={startPageUrl}
                breadcrumb={breadcrumb}
                searchParams={searchParams}
                useCustomCMP={useCustomCMP}
                affiliateNumber={affiliateNumber}
              />
            </Route>
          );
        })}
      </Switch>
      <LeaveModal />
    </>
  );
});

export default App;
