/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  excess: 'excess',
  no_excess: 'no_excess',
  agb: 'agb',
  description: 'description',
  headline: 'headline',
  legalInformation: 'legalInformation',
  noInsurance: 'noInsurance',
  included: 'included',
  notIncluded: 'notIncluded',
  from: 'from',
  recommendation: 'recommendation',
  agbSpecial: 'agbSpecial',
  remark: 'remark',
  remark2: 'remark2',
  insuranceCheckboxLabel1: 'insuranceCheckboxLabel1',
  insuranceCheckboxLabel2: 'insuranceCheckboxLabel2',
  insuranceCheckboxLabel3: 'insuranceCheckboxLabel3'
}; export default Keys;