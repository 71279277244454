/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  moreInformation: 'moreInformation',
  accept: 'accept',
  customTitle: 'customTitle',
  customMapDescription: 'customMapDescription',
  customVideoDescription: 'customVideoDescription',
  cookieSettings: 'cookieSettings'
}; export default Keys;