/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-06-06 06:50:01 */

const Keys = {
  vehicleBookedBy: 'vehicleBookedBy',
  participants: 'participants',
  message: 'message',
  isBooked: 'isBooked',
  isRequest: 'isRequest',
  newsletter: 'newsletter',
  myClimate: 'myClimate',
  homepageReturn: 'homepageReturn',
  bookingNumber: 'bookingNumber',
  successHeadline: 'successHeadline',
  successAvailableInfo: 'successAvailableInfo',
  successOnRequestInfo1: 'successOnRequestInfo1',
  successOnRequestInfo2: 'successOnRequestInfo2'
}; export default Keys;